.static-container {
    color: white;
    font-family: "Roboto Light", sans-serif !important;
    min-height: 100vh;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .static-container {
        height: auto;
    }
}

@media screen and (min-height: 1024px) {
    .static-container {
        height: 100%;
    }
}

.static-header {
    margin: 0 auto;
    width: 1193px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .static-header {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 1200px) and (min-width: 1000px){
    .static-header {
        padding-left: 5px;
        padding-right: 5px;
        width: auto;
    }
}

.static-logo {
    background: url("../../images/landing/landing-logo.png") no-repeat;
    background-size: contain;
    width: 523px;
    height: 58px;
    margin: 0 auto;
    cursor: pointer;
}

@media screen and (max-width: 1000px){
    .static-logo {
        background: url("../../images/landing/landing-logo.png") no-repeat;
        background-size: contain;
        width: 236px;
        height: 26px;
        margin: 0;
    }
}

.static-page-about {
    background: url("../../images/static/bg_aboutus.jpg") no-repeat 50%;
    background-size: cover;
    min-height: 100vh;
    padding-top: 30px;
    position: relative;
}

.static-page-product {
    background: url("../../images/static/bg_product.jpg") no-repeat 50%;
    background-size: cover;
    min-height: 100vh;
    padding-top: 30px;
    position: relative;
}

.static-page-history {
    background: url("../../images/static/bg_gold.jpg") no-repeat 50%;
    background-size: cover;
    min-height: 100vh;
    padding-top: 30px;
    position: relative;
}


.static-page-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 84px;
    position: relative;
}

@media screen and (max-width: 1000px){
    .static-page-content-container {
        padding-top: 10px;
    }
}

.static-page-content {
    display: flex;
    justify-content: space-between;
    width: 1193px;
    margin: 0 auto;
    padding-top: 30px;
    position: relative;
}

@media screen and (max-width: 1000px){
    .static-page-content {
        display: block;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.static-page-content > div {
    width: 565px;
}

@media screen and (max-width: 1000px){
    .static-page-content > div {
        width: 100%;
    }
}

.static-page-button {
    width: 105px;
    height: 38px;
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    background: transparent;
}

.static-page-button:hover {
    background: rgba(255,255,255, 0.1);
}

.static-page-title {
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
}

@media screen and (max-width: 1000px){
    .static-page-title {
        margin-top: 43px;
    }
}

.static-page-p {
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px){
    .static-page-p {
        font-size: 18px;
    }
}

.static-page-p a {
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF !important;
    overflow-wrap: anywhere;
}

.static-page-p a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1000px){
    .static-page-p a {
        font-size: 18px;
    }
}

.static-page-p-title {
    font-weight: 700;
    margin-bottom: 10px;
}

.static-page-img-about1 {
    background: url("../../images/static/aboutus-1.jpg") no-repeat;
    background-size: cover;
    width: 565px;
    height: 377px;
}

@media screen and (max-width: 1000px){
    .static-page-img-about1 {
        background: url("../../images/static/aboutus-1.jpg") no-repeat;
        background-size: contain;
        width: 100%;
    }
}

.static-page-img-about2 {
    background: url("../../images/static/aboutus-2.jpg") no-repeat;
    background-size: cover;
    width: 565px;
    height: 377px;
}

@media screen and (max-width: 1000px){
    .static-page-img-about2 {
        background: url("../../images/static/aboutus-2.jpg") no-repeat;
        background-size: contain;
        width: 100%
    }
}

.static-page-img-about3 {
    background: url("../../images/static/management.png") no-repeat;
    background-size: contain;
    width: 565px;
    height: 160px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px){
    .static-page-img-about3 {
        background: url("../../images/static/management.png") no-repeat;
        background-size: contain;
        width: 100%
    }
}

.static-page-img-history1 {
    background: url("../../images/static/historygold.jpg") no-repeat;
    background-size: contain;
    width: 565px;
    height: 370px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px){
    .static-page-img-history1 {
        background: url("../../images/static/historygold.jpg") no-repeat;
        background-size: contain;
        width: 100%
    }
}

.static-lang-selector-container {
    position: absolute;
    top: 20px;
    right: 0;
}

@media screen and (max-width: 1000px){
    .static-lang-selector-container {
        position: relative;
        top: 0;
        right: unset;
    }
}


.static-flex-direction {

}

@media screen and (max-width: 1000px){
    .static-flex-direction {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
}

.static-page-margin {
    margin-bottom: 30px;
}

.static-product-container {
    width: 565px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
}

@media screen and (max-width: 1000px){
    .static-product-container {
        width: 100%;
        height: auto;
        padding-top: 18px;
        padding-bottom: 18px;
    }
}

.static-product-container-black {
    background: rgba(35, 35, 35, 0.8);
}

.static-product-container-purple {
    background: rgba(79, 22, 88, 0.8);
}

.static-product-container-red {
    background: rgba(139, 39, 51, 0.8);
}

.static-product-container-blue {
    background: rgba(17, 33, 118, 0.8);
}

.static-product-container-green {
    background: rgba(12, 80, 10, 0.8);
}

.static-product-container-white {
    background: rgba(200, 200, 200, 0.8);
}

.static-product-icon {
    min-width: 150px;
    min-height: 150px;
    width: 150px;
    height: 150px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.static-product-black-icon {
    background: url("../../images/static/packs/gold.png") no-repeat;
    background-size: contain;
}

.static-product-purple-icon {
    background: url("../../images/static/packs/platinum.png") no-repeat;
    background-size: contain;
}

.static-product-red-icon {
    background: url("../../images/static/packs/silver.png") no-repeat;
    background-size: contain;
}

.static-product-blue-icon {
    background: url("../../images/static/packs/bronze.png") no-repeat;
    background-size: contain;
}

.static-product-green-icon {
    background: url("../../images/static/packs/copper.png") no-repeat;
    background-size: contain;
}

.static-product-white-icon {
    background: url("../../images/static/packs/brass.png") no-repeat;
    background-size: contain;
}

.static-product-row {
    font-size: 22px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    position: relative;
}

@media screen and (max-width: 1000px){
    .static-product-row {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
    }
}

.static-product-pack-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
}

@media screen and (max-width: 1000px){
    .static-product-pack-title {
        font-size: 24px;
        line-height: 30px;
    }
}

.visa-icon {
    background: url("../../images/static/visa.png") no-repeat;
    background-size: contain;
    width: 90px;
    height: 70px;
    position: absolute;
    left: 230px;
    top: -20px;
}

.priority-icon {
    background: url("../../images/static/priority.png") no-repeat;
    background-size: contain;
    width: 90px;
    height: 70px;
    position: absolute;
    left: 250px;
    top: -20px;
}

@media screen and (max-width: 1024px){
    .visa-icon {
        left: 125px;
        width: 70px;
    }
    .priority-icon {
        left: 140px;
        width: 70px;
    }
}