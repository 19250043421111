.resources-container {
    min-height: 400px;
    width: 100%;
}

.resources-wrapper {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: space-between;
}

.resource-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E2E2E2;
    border-radius: 2px;
    cursor: pointer;
    height: 32px;
    padding: 3px;
}

.resource-block {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 45%;
    max-width: 45%;
    overflow-wrap: normal;
}

@media screen and (max-width: 1024px){
    .resource-block {
        width: 100%;
        max-width: 100%;
    }
}

.download-icon {
    background: url("../../../../../images/dashboard/download-icon.png") no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.download-icon-white {
    background: url("../../../../../images/dashboard/download-icon-white.png") no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}