.dashboard-layout {
    background: #F4F4F4;
    height: auto;
}


@media screen and (max-width: 1000px){
    .dashboard-layout {
        background: #191919;
        color: #FFFFFF;
        height: 100%;
    }
}


.dashboard-content {
    width: 1120px;
    margin-left: 105px;
    padding-top: 105px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px){
    .dashboard-content {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 103px;
    }
}