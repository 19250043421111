.sidebar {
    width: 80px;
    height: 100vh;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 13px;
    box-sizing: border-box;
    background: #232323;
    color: #FFFFFF;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
}

@media screen and (max-width: 1000px){
    .sidebar {
        position: fixed;
        width: 100%;
        height: 70px;
        bottom: 0;
        top: initial;
        padding-top: 33px;
        display: flex;
        align-items: center;
        min-height: initial;
    }
}

.sidebar-item {
    margin: 0 auto;
    margin-bottom: 35px;
    cursor: pointer;
    color: #878787;
    font-size: 13px;
    line-height: 14px;
}

.sidebar-item-active {
    color: #FFFFFF;
}

.sidebar-item:hover {
    color: #FFFFFF;
}

.sidebar-item-title {
    margin-top: 5px;
    overflow-wrap: break-word;
}

.sidebar-logo {
    width: 55px;
    height: 55px;
    margin: 0 auto;
    background: url("../../images/sidebar/logo.png") no-repeat;
    background-size: contain;
}

.sidebar-icon {
    width: 39px;
    height: 39px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px){
    .sidebar-icon {
        width: 30px;
        height: 30px;
    }
}

.icon-dashboard {
    background: url("../../images/sidebar/dashboard.png") no-repeat;
    background-size: contain;
}

.sidebar-item:hover > .icon-dashboard {
    background: url("../../images/sidebar/dashboard-white.png") no-repeat;
    background-size: contain;
}

.icon-dashboard-active {
    background: url("../../images/sidebar/dashboard-white.png") no-repeat;
    background-size: contain;
}

.icon-network {
    background: url("../../images/sidebar/network.png") no-repeat;
    background-size: contain;
}

.sidebar-item:hover > .icon-network {
    background: url("../../images/sidebar/network-white.png") no-repeat;
    background-size: contain;
}

.icon-network-active {
    background: url("../../images/sidebar/network-white.png") no-repeat;
    background-size: contain;
}

.icon-epin {
    background: url("../../images/sidebar/epin.png") no-repeat;
    background-size: contain;
}

.sidebar-item:hover > .icon-epin {
    background: url("../../images/sidebar/epin-white.png") no-repeat;
    background-size: contain;
}

.icon-epin-active  {
    background: url("../../images/sidebar/epin-white.png") no-repeat;
    background-size: contain;
}

.icon-wallet {
    background: url("../../images/sidebar/wallet.png") no-repeat;
    background-size: contain;
}

.sidebar-item:hover > .icon-wallet {
    background: url("../../images/sidebar/wallet-white.png") no-repeat;
    background-size: contain;
}

.icon-wallet-active  {
    background: url("../../images/sidebar/wallet-white.png") no-repeat;
    background-size: contain;
}

.icon-events {
    background: url("../../images/sidebar/events.png") no-repeat;
    background-size: contain;
}

.sidebar-item:hover > .icon-events{
    background: url("../../images/sidebar/events-white.png") no-repeat;
    background-size: contain;
}

.icon-events-active  {
    background: url("../../images/sidebar/events-white.png") no-repeat;
    background-size: contain;
}

.icon-signup {
    background: url("../../images/sidebar/signup.png") no-repeat;
    background-size: contain;
}

.sidebar-item:hover > .icon-signup {
    background: url("../../images/sidebar/signup-white.png") no-repeat;
    background-size: contain;
}

.icon-signup-active {
    background: url("../../images/sidebar/signup-white.png") no-repeat;
    background-size: contain;
}

.sidebar-mobile-panel {
    width: 100%;
    height: 33px;
    border-top: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    box-sizing: border-box;
    position: fixed;
    bottom: 70px;
    background: #232323;
    color: #878787;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
}

.sidebar-mobile-item {

}

.sidebar-mobile-item-active {
    color: #FFFFFF;
}