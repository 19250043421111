.lrt-sales-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
}
.lrt-sales-progress {
    background: #385682;
    width: 100%;
    height: 10px;
    border-radius: 42px;
}

.lrt-sales-progress-filled {
    background: #5297FF;
    height: 10px;
    border-radius: 42px;
    width: 1%;
    transition: width 1s;
}

.lrt-sales-button {
    padding: 0 20px;
}
