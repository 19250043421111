.modal-education-time-table {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    margin: 32px auto 0;
}

.modal-education-time-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;
}

.modal-education-time-table-cell {
    width: 25%;
    text-align: left;
}

.modal-education-time-table-cell:first-child {
    width: 50%;
}

.modal-education-time-button-container {
    height: 65px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.modal-education-time-button {
    width: 360px;
    cursor: pointer;
}

.modal-education-time-link {
    color: #808080;
    cursor: pointer;
}

