/*Now the CSS*/
* {margin: 0; padding: 0;}

.custom-tree {
    /*overflow: scroll;*/
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.custom-tree ul {
    display: flex;
    padding-top: 20px; position: relative;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    overflow-x: scroll !important;
}

.custom-tree li {
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.custom-tree li::before, .custom-tree li::after{
    content: '';
    position: absolute; top: 0; right: 50%;
    border-top: 1px solid #ccc;
    width: 50%; height: 20px;
}
.custom-tree li::after{
    right: auto; left: 50%;
    border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.custom-tree li:only-child::after, .custom-tree li:only-child::before {
    display: none;
}

/*Remove space from the top of single children*/
.custom-tree li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.custom-tree li:first-child::before, .custom-tree li:last-child::after{
    border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.custom-tree li:last-child::before{
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
.custom-tree li:first-child::after{
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.custom-tree ul ul::before{
    content: '';
    position: absolute; top: 0; left: 50%;
    border-left: 1px solid #ccc;
    width: 0; height: 20px;
}

.custom-tree-table {
    width: 50%;
}

.custom-tree-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-tree-cell-caption {
    font-weight: 700;
    margin-bottom: 5px;
}

.custom-tree-cell {
    margin-top: 3px;
}


.custom-tree-row > .custom-tree-cell:first-child {
    text-align: left;
}

.custom-tree-row > .custom-tree-cell:last-child {
    text-align: right;
}

.custom-tree-item-sponsor {
    width: 70px !important;
    padding-bottom: 5px !important;
    text-align: center !important;
}

.custom-tree-item-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.custom-tree-item-inner > div:nth-child(1) {
    margin-right: 10px;
}

.custom-tree-item-sponsor-inner {
    text-align: center;
    margin: 0 auto !important;
}

.ul-add {
    display: flex;
    justify-content: center !important;
}


.custom-tree-item-add {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 24px !important;
}

.custom-tree-item {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ccc;
    font-size: 12px;
    padding: 5px;
    padding-bottom: 20px;
    width: 220px;

    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.custom-tree-item:hover, .custom-tree-item:hover+ul li .custom-tree-item {
    background: #c8e4f8; color: #000; border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.custom-tree-item:hover+ul li::after,
.custom-tree-item:hover+ul li::before,
.custom-tree-item:hover+ul::before,
.custom-tree-item:hover+ul ul::before{
    border-color:  #94a0b4;
}

.tree-item-toggle {
    border: 1px solid black;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-up {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.arrow-down {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.network-circle {
    height: 40px;
    width: 40px;
    border: 1px solid #000000;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 3px;
    margin-top: 1px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 1000px){
    .network-circle {
        border: 1px solid #FFFFFF;
    }
}

.network-profile-icon {
    background: url("../../../../../images/top-panel/user.png") no-repeat center;
    background-size: contain;
    width: 25px;
    height: 25px;
}

@media screen and (max-width: 1000px){
    .network-profile-icon {
        background: url("../../../../../images/top-panel/user-white.png") no-repeat center;
        background-size: contain;
        width: 25px;
        height: 25px;
    }
}