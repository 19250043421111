.top-panel {
    height: 80px;
    width: 100%;
    top: 0;
    position: absolute;
    background: #FFFFFF;
    color: #303030;
    display: flex;
    align-items: center;
    padding: 20px 20px 20px 100px;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 0;
}

.top-panel-item {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-right: 1px solid #164E02;
}

.without-border {
    border: none !important;
}

.top-panel-item > div:nth-child(2) {
    margin-left: 20px;
}

.publishing-rights-top {
}

.publishing-rights-bottom {
    font-size: 12px;
    color: #219653;
    margin-top: 4px;
    text-align: right;
}

.pay-rank {
    font-size: 12px;
    color: #219653;
    margin-top: 4px;
    text-align: right;
}

.icon-account {
    width: 24px;
    height: 24px;
    background: url("../../images/top-panel/user.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    margin: 0 12px;
}

.icon-bell {
    width: 24px;
    height: 24px;
    background: url("../../images/top-panel/bell.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: 12px;
}

.top-panel-user-info {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-panel-user-info-username {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}