.mobile-blocks-container {

}

.mobile-blocks-row{
    display: flex;
    box-sizing: border-box;
}

.mobile-blocks-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background: #000000;
    border: 1px solid #898989;
    box-sizing: border-box;
    width: 50%;
    font-size: 14px;
}

.mobile-blocks-cell-full-width {
    width: 100% !important;
}