.share-link {
    width: 435px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #303030;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .share-link {
        width: 100%;
        height: 33px;
        color: #FFFFFF;
        border: 1px solid #D4D4D4;
        border-radius: 2px;
        background: initial;
    }
}

.share-link-container {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.social-block {
    margin-top: 20px;
}

.social-icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    margin: 15px 0;
}

.social-icon {
    width: 38px;
    height: 38px;
    cursor: pointer;
}

.fb-icon {
    background: url("../../images/social/fb-gray.png") no-repeat;
    background-size: contain;
}

.twitter-icon {
    background: url("../../images/social/twitter-gray.png") no-repeat;
    background-size: contain;
}

.mail-icon {
    background: url("../../images/social/mail-gray.png") no-repeat;
    background-size: contain;
}

.messenger-icon {
    background: url("../../images/social/messenger-gray.png") no-repeat;
    background-size: contain;
}

.linkedin-icon {
    background: url("../../images/social/linkedin-gray.png") no-repeat;
    background-size: contain;
}

.whatsapp-icon {
    background: url("../../images/social/telegram-gray.png") no-repeat;
    background-size: contain;
}

.telegram-icon {
    background: url("../../images/social/telegram-gray.png") no-repeat;
    background-size: contain;
}

.share-icon {
    background: url("../../images/social/share-gray.png") no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 5px;
}

@media screen and (max-width: 1000px){
    .social-icon {
        width: 30px;
        height: 30px;
    }

    .fb-icon {
        background: url("../../images/social/fb-white.png") no-repeat;
        background-size: contain;
    }

    .twitter-icon {
        background: url("../../images/social/twitter-white.png") no-repeat;
        background-size: contain;
    }

    .mail-icon {
        background: url("../../images/social/mail-white.png") no-repeat;
        background-size: contain;
    }

    .messenger-icon {
        background: url("../../images/social/messenger-white.png") no-repeat;
        background-size: contain;
    }

    .linkedin-icon {
        background: url("../../images/social/linkedin-white.png") no-repeat;
        background-size: contain;
    }

    .whatsapp-icon {
        background: url("../../images/social/whatsapp-white.png") no-repeat;
        background-size: contain;
    }

    .telegram-icon {
        background: url("../../images/social/whatsapp-white.png") no-repeat;
        background-size: contain;
    }

    .share-icon {
        background: url("../../images/social/share-white.png") no-repeat;
        background-size: contain;
    }
}
