* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #303030;
    background: #F4F4F4;
}

@media screen and (max-width: 1000px) {
    body {
        font-size: 14px;
        background: #191919;
    }
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(./fonts/Quicksand/Quicksand_Book.otf);
}

#root {
    height: 100%;
}


:root {
    --content-width: 1178px;
}

a {
    text-decoration: none;
}

.container {
    position: relative;
    height: 100%;
}

@media screen and (max-width: 1000px) {
    .container {
        box-sizing: border-box;
    }
}

.content {
    width: 1178px;
    max-width: 1178px;
    margin: 0 auto;
    height: 100%;
}

@media screen and (max-width: 1000px) {
    .content {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

.modal {
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #303030;
    font-size: 16px;
    line-height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    outline: none;
    min-width: 480px;
    /*width: 603px;*/
    height: auto;
    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 5px;
}

@media screen and (max-width: 1000px) {
    .modal {
        padding: 10px;
        width: 95% !important;
        min-width: min-content !important;
        height: auto !important;
        text-align: center;
    }
}

.modal-button-container {
    display: flex;
    justify-content: center;
    margin: 30px auto 0;
}

.modal-content {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
}

.modal-content.center {
    text-align: center;
}

.modal-content > form {
    margin-top: 50px;
}

.form-item {
    margin-bottom: 50px;
    position: relative;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .form-item {
        margin-bottom: 30px;
    }
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], select {
    width: 100%;
    font-size: 16px;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 1px;
    border: none;
    border-bottom: 1px solid #818181 !important;
    transition: all .2s;
    color: #303030;
}

@media screen and (max-width: 1000px) {
    input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"] {
        background: #191919;
        color: white;
    }
}

.guest-input, #country {
    background: white !important;
    color: #303030 !important;
}

select {
    color: #989898;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="number"]:focus {
    outline: none 0 !important;
    border-color: #2F80ED !important;
}

input:focus + label,
input:not(:placeholder-shown) + label {
    transform: translateY(1px) scale(.8);
}

input[type="text"]::placeholder, input[type="email"]::placeholder, input[type="password"]::placeholder, input[type="tel"]::placeholder, input[type="number"]::placeholder {
    color: transparent;
}

input[type="text"]:focus:invalid, input[type="email"]:focus:invalid, input[type="password"]:focus:invalid, input[type="tel"]:focus:invalid, input[type="number"]:focus:invalid {
    border-color: #D8000C !important;
}

input[type="checkbox"] {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.checkbox-label {
    font-size: 12px;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

label {
    cursor: auto;
    position: absolute;
    top: -20px;
    left: 2px;
    color: #AAA;
    display: block;
    opacity: 1;
    transform: translateY(1.25em);
    transform-origin: 0 0;
    transition: all .2s;
    z-index: 0;
}

button {
    width: auto;
    min-width: 97px;
    height: 35px;
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
    border: none;
    cursor: pointer;
    padding: 0 5px;
}

.button-large {
    width: 100% !important;
}

.button-blue {
    background: #2F80ED !important;
    color: #FFFFFF !important;
}

.button-green {
    background: #219653 !important;
    color: #FFFFFF !important;
}

.button-grey {
    background: #D0C9D6 !important;
    color: #FFFFFF !important;
}

.button-purple {
    background: #BE52F2 !important;
    color: #FFFFFF !important;
}

.button-white {
    background: #FFFFFF !important;
    color: #636363 !important;
    border: 1px solid #939393;
    border-radius: 1px;
}

.form-button-container {
    margin: 0 auto;
    width: 100%;
    min-height: 35px;
    display: flex;
    justify-content: center;
}

.form-button-container > button:nth-child(2) {
    margin-left: 28px;
}

.link {
    color: #6979F8;
    cursor: pointer;
}

.link-underline {
    text-decoration: underline;
    cursor: pointer;
    padding: 0 5px;
}

.MuiIconButton-root {
    width: auto !important;
    height: auto !important;
}


.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.error {
    font-size: 14px;
    color: #D8000C;
}

.input-error {
    font-size: 12px;
    color: #D8000C;
    position: absolute;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background: #F4F4F4;
    box-sizing: border-box;
    border-radius: 10px;
    width: 534px;
    height: 392px;
    padding: 33px 47px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dropdown-content-item {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    width: 45%;
    cursor: pointer;
    font-size: 18px !important;
}

.qr-code-container {
    text-align: center;
    margin: 0 auto;
}

.qr-code-container > div:nth-child(2) {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 600;
}


select {
    padding-left: 0 !important;
}

@media screen and (max-width: 1000px) {
    select {
        background: #191919;
        color: #FFFFFF;
    }
}

.hidden {
    display: none;
}

.center {
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    opacity: 0.3;
}

.modal-close-button:hover {
    opacity: 1;
    cursor: pointer;
}

.modal-close-button:before, .modal-close-button:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: #333;
}

.modal-close-button:before {
    transform: rotate(45deg);
}

.modal-close-button:after {
    transform: rotate(-45deg);
}

.change-input-button {
    position: absolute;
    right: 0;
    top: 0;
    color: #A0A0A0;
    font-size: 14px;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .dashboard-mobile-colors {
        background: #191919 !important;
        color: white !important;
    }
}

.white-input {
    background: white !important;
    color: #303030 !important;
}

.custom-input {
    border: 1px solid #818181 !important;
}

.custom-input::placeholder {
    color: #888888 !important;
}

.custom-input-label {
    margin-bottom: 5px;
}

.table-actions {
    display: flex;
    column-gap: 3px;
}
.table-actions > div {
    cursor: pointer;
}