.menu-dropdown {
    position: relative;
    display: inline-block;
}

.menu-dropdown-content {
    display: none;
    position: absolute;
    background: #FFFFFF;
    box-sizing: border-box;
    right: 0;
    width: 150px;
    height: auto;
    padding: 15px;
    color: #303030;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    text-align: right;
}

.menu-dropdown:hover .menu-dropdown-content {
    display: block;
}

.menu-dropdown-item {
    height: 30px;
    cursor: pointer;
    margin-bottom: 10px
}

.menu-dropdown-item:hover {
    color: #256FDD;
}