.top-panel-mobile {
    height: 40px;
    width: 100%;
    background: #000;
    color: #FFF;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.icon-account-mobile {
    width: 24px;
    height: 24px;
    background: url("../../images/top-panel/user-white.png") no-repeat;
    background-size: contain;
}

.icon-bell-mobile {
    width: 24px;
    height: 24px;
    background: url("../../images/top-panel/bell-white.png") no-repeat;
    background-size: contain;
    margin-left: 8px;
}

.icons-right-block-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-menu {
    background: #191919;
    width: 220px;
    box-sizing: border-box;
    padding-left: 37px;
    padding-top: 27px;
    height: auto;
    color: #FFFFFF;
}

.mobile-menu-item {
    margin-bottom: 30px;
}

.MuiPaper-root {
    background: #191919 !important;
}
