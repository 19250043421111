.event-calendar-container {
    width: 660px;
    max-height: 500px;
    height: 500px;
    overflow-y: scroll;
}

@media screen and (max-width: 1024px){
    .event-calendar-container {
        max-height: 100%;
        padding-bottom: 50px;
    }
}

.event-calendar-wrapper {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
}

@media screen and (max-width: 1024px){
    .event-calendar-wrapper {
        flex-direction: column;
        row-gap: 30px;
    }
}

.event-content-wrapper {
    width: 48%;
    white-space: pre-wrap;
}

@media screen and (max-width: 1024px){
    .event-content-wrapper {
        width: 100%;
    }
}

.calendar-wrapper {
    width: 48%;
    position: relative;
    margin-top: 20px;
}

@media screen and (max-width: 1024px){
    .calendar-wrapper {
        width: 100%;
    }
}

.calendar-table {
    clear: both;
    width: 100%;
    border-collapse: collapse;
    color: #444;
}

@media screen and (max-width: 1024px){
    .calendar-table {
        color: white;
    }
}

.calendar-table td {
    height: 48px;
    text-align: center;
    vertical-align: middle;
    width: 10%;
}
.calendar-table td.not-current {
    color: #c0c0c0;
}
.calendar-table td.today {
    font-weight: 700;
}

.calendar-table thead td {
    border: none;
    color: #28283b;
    text-transform: uppercase;
    font-size: 1.5em;
}

@media screen and (max-width: 1024px){
    .calendar-table thead td {
        color: white;
    }
}

#btnPrev {
    left: 0;
    top: 13px;
}
#btnNext {
    right: 0;
    top: 13px;
}
#btnPrev,
#btnNext {
    position: absolute;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    color: #c0c0c0;
    cursor: pointer;
    transition: all 0.3s ease;
}
#btnPrev:hover,
#btnNext:hover {
    color: #28283b;
    font-weight: bold;
}

.days {
    border-bottom: 1px solid #7B7B7B;
}

.calendar-webinar, .calendar-meeting {
    cursor: pointer;
    position: relative;
    text-decoration: underline;
}

.calendar-webinar:before {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #219653;
    top: 35px;
    left: 10px;
}

.calendar-meeting:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #2F80ED;
    top: 35px;
    right: 10px;
}

@media screen and (max-width: 1024px){
    .calendar-webinar:before {
        left: 15px;
    }
    .calendar-webinar:after {
        right: 15px;
    }
}

.event-calendar-separator {
    width: 1px;
    min-height: 200px;
    margin-top: -39px;
    background: #E2E2E2;
}

.event-tabs {
    display: flex;
    align-items: center;
    column-gap: 50px;
}

.event-tabs-item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}

.event-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.event-webinars-icon {
    background: #219653;
}

.event-meetings-icon {
    background: #2F80ED;
}

.event-content {
    margin-top: 50px;
    font-size: 14px;
    overflow-y: scroll;
}

/*.event-calendar-container::-webkit-scrollbar {*/
/*    -webkit-appearance: none;*/
/*    width: 7px;*/
/*}*/

/*.event-calendar-container::-webkit-scrollbar-thumb {*/
/*    border-radius: 4px;*/
/*    background-color: rgba(0, 0, 0, .5);*/
/*    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);*/
/*}*/