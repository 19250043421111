.block-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block {
    width: 170px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    padding: 10px;
    box-sizing: border-box;
    color: #303030;
    text-align: right;
}

.block-small {
    height: 83px;
    width: 120px !important;
}

.block-caption {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
}

.block-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
}

.block-purple1 {
    border-left: 2px solid #0C0372;
}

.block-purple2 {
    border-left: 2px solid #1302DB;
}

.block-purple3 {
    border-left: 2px solid #7011E9;
}

.block-brown {
    border-left: 2px solid #B37600
}

.block-green1 {
    border-left: 2px solid #028125
}

.block-green2 {
    border-left: 2px solid #29CC6A
}

.block-red1 {
    border-left: 2px solid #B3000B;
}

.block-red2 {
    border-left: 2px solid #F80817;
}

.block-red3 {
    border-left: 2px solid #ED1616;
}

.block-yellow {
    border-left: 2px solid #F0F43B;
}

.block-orange {
    border-left: 2px solid #F2AD46
}

.block-gray {
    border-left: 2px solid #E7E7E7
}

.block-blue {
    border-left: 2px solid #1241E7
}

.block-black {
    border-left: 2px solid #303030
}







