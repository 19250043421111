.landing-container {
    color: white;
    font-family: "Roboto Light", sans-serif !important;
    min-height: 100vh;
}

@media screen and (max-width: 1000px) {
    .landing-container {
        height: auto;
    }
}

@media screen and (min-height: 1024px) {
    .landing-container {
        height: 100%;
    }
}

.landing-container-step1 {
    background: url("../../images/landing/landing-bg1.jpeg") no-repeat 50%;
    background-size: cover;
}

.landing-container-step2 {
    background: url("../../images/landing/landing-bg2.jpeg") no-repeat 50%;
    background-size: cover;
}

.landing-container-step3 {
    background: url("../../images/landing/landing-bg3.jpeg") no-repeat 50%;
    background-size: cover;
}

.landing-container-step4 {
    background: url("../../images/landing/landing-bg4.jpeg") no-repeat 50%;
    background-size: cover;
}



.landing-content {
    width: 1193px;
    margin: 0 auto;
    padding-top: 30px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .landing-content {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 1200px) and (min-width: 1000px){
    .landing-content {
        padding-left: 5px;
        padding-right: 5px;
        width: auto;
    }
}

.landing-content > p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 34px !important;
}

@media screen and (max-width: 1000px){
    .landing-content > p {
        font-size: 18px;
    }
}


.landing-content > h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 26px;
    margin-top: 43px;
}

.landing-img1 {
    background: url("../../images/landing/landing-img1.png") no-repeat center;
    background-size: cover;
    width: 169px;
    height: 279px;
}

.landing-img2 {
    background: url("../../images/landing/landing-img2.png") no-repeat center;
    background-size: contain;
    width: 260px;
    height: 519px;
}

.landing-img3 {
    background: url("../../images/landing/landing-img3.png") no-repeat center;
    background-size: contain;
    width: 260px;
    height: 519px;
}

.landing-img4 {
    background: url("../../images/landing/landing-img4.png") no-repeat center;
    background-size: contain;
    width: 260px;
    height: 519px;
}

.landing-logo {
    background: url("../../images/landing/landing-logo.png") no-repeat;
    background-size: contain;
    width: 523px;
    height: 58px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px){
    .landing-logo {
        background: url("../../images/landing/landing-logo.png") no-repeat;
        background-size: contain;
        width: 236px;
        height: 26px;
        margin: 0 auto;
    }
}

.landing-logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing-lang-selector-container {
    position: absolute;
    top: 45px;
    right: 0;
    display: flex;
    column-gap: 35px;
    align-items: center;
}

@media screen and (max-width: 1000px){
    .landing-lang-selector-container {
        right: 10px;
        top: 28px;
    }
}


.landing-opportunity {
    margin-top: 34px !important;
    margin-bottom: 15px !important;
    padding-left: 3px;
    padding-right: 3px;
    width: 471px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
}

.landing-hint {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px !important;
}

.landing-link {
    text-decoration: underline;
    color: white !important;
    cursor: pointer;
    padding: 0 5px;
}

.landing-footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
}

.landing-footer {
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 1193px;
    height: 28px;
    color: #FFFFFF;
    margin-top: 100px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@media screen and (max-width: 1000px) {
    .landing-footer {
        width: 360px;
        height: auto;
        display: inline-flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 20px;
        justify-content: center;
    }
}

@media screen and (max-width: 1200px) {
    .landing-footer {
        width: 99%;
    }
}


.landing-footer > div {
    cursor: pointer;
}

.landing-footer > div:hover {
    color: white;
}

@media screen and (max-width: 1000px) {
    .landing-footer > div {
        margin-top: 10px;
    }
}

.landing-address {
    color: #9A9A9A;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-size: 12px;
}


.landing-content-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 105px;
    margin-bottom: 65px;
}

.landing-content-left {
    width: 864px;
}

@media screen and (max-width: 1200px) {
    .landing-content-left {
        /*width: 50%;*/
    }
}

.landing-caption1 {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 80px;
    color: #FFFFFF;
}

@media screen and (max-width: 1000px){
    .landing-caption1 {
        font-size: 38px;
        line-height: 46px;
        text-align: center;
    }
}

.landing-caption2 {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    color: #FFFFFF;
    margin-top: 81px;
    max-width: 696px;
}

@media screen and (max-width: 1000px){
    .landing-caption2 {
        font-size: 26px;
        line-height: 34px;
        text-align: center;
        max-width: 100%;
    }
}


.landing-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 592px;
}

@media screen and (max-width: 1000px){
    .landing-button-container {
        width: 100%;
        max-width: 592px;
    }
}

.landing-button {
    font-family: 'Quicksand', serif;
    padding: 17px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    height: 80px;
    width: 281px;
}

@media screen and (max-width: 1000px){
    .landing-button {
        width: 156px;
        height: 48px;
        font-size: 20px;
        line-height: 25px;
        padding: 12px;
    }
}

.landing-login-button {
    font-family: 'Quicksand', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 103px;
    height: 38px;
}

.landing-login-button-mobile {
    background: url("../../images/landing/login.png") no-repeat;
    background-size: cover;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.landing-button-green {
    background: #4E955D !important;
}

.landing-button-transparent {
    background: none !important;
    border: 1px solid #FFFFFF;
}

.landing-login-link {
    cursor: pointer;
    margin-top: 11px;
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.landing-ref-info {
    position: absolute;
    top: 126px;
    left: 0;
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

@media screen and (max-width: 1000px){
    .landing-ref-info {
        position: absolute;
        top: 75px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        font-family: 'Quicksand', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
}


