.tree-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
    overflow: scroll;
}

.tree-container {
    max-width: 100%;
    min-width: max-content;
    overflow: scroll;
}

.tree-search-panel {
    height: 120px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

@media screen and (max-width: 1024px){
    .tree-search-panel {
        flex-direction: column;
        row-gap: 20px;
    }
}

.tree-tab {
    width: 190px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #303030;
    cursor: pointer;
}

.tree-tab-active {
    border: 1px solid #7E7E7E !important;
    color: #7E7E7E !important;
}

.tree-button {
    background: url("../../../images/dashboard/tree-button.png") no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.tree-button-with-label {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #828282;
}