.auth-container {
    background: url("../../images/landing/landing-bg.jpeg");
    background-size: cover;
    background-position: center;
    min-height: auto;
    height: auto;
    color: white;
    font-family: Roboto, sans-serif;
}

.auth-content {
    color: black;
    width: 639px;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 45px 102px;
}

@media screen and (max-width: 1000px) {
    .auth-content {
        width: 100%;
        padding: 10px 20px;
    }
}

.auth-content > .caption {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}


.logo {
    background: url("../../images/logo.png");
    background-size: cover;
    width: 55px;
    height: 55px;
    margin: 32px auto 100px auto;
    cursor: pointer;
}

.login-help {
    margin: 0 auto;
    margin-top: 30px;
    width: 256px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
}

.login-help > div {
    margin-bottom: 20px;
}

.country-label {
    cursor: auto;
    position: absolute;
    top: -20px;
    left: 2px;
    color: #AAA;
    font-size: 14px;
}

.login-link {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}

.customer-info-table {
    color: #989898;
    margin-top: 27px;
    margin-bottom: 70px;
}

.customer-info-table > tbody > tr > td {
    padding: 5px 0;
    min-width: 150px;
}

.selected-pack-container {
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F4F4F4;
    padding: 8px 28px;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 30px;
    color: black !important;
}


.selected-pack-container > img {
    width: 45px;
    height: 45px;
}

.input-label {
    margin-bottom: 5px;
}

.waiting-content {
    color: #303030;
    font-size: 16px;
    line-height: 22px;
}

.upgrade-form {
    margin-top: 30px;
}

.waiting-button-container {
    display: flex;
    column-gap: 10px;
    justify-content: center;
}

@media screen and (max-width: 1024px){
    .waiting-button-container {
        flex-direction: column;
        row-gap: 10px;
    }
}

.visa-check-container {
    background: #2F80ED;
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
