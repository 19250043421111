.dashboard-blocks-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .dashboard-blocks-container {
        display: block;
    }
}

.dashboard-block {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    box-sizing: border-box;
    padding: 20px;
}

@media screen and (max-width: 1000px) {
    .dashboard-block {
        display: block;
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
        height: auto !important;
    }
}

.dashboard-block-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .dashboard-block-container {
        display: block;
        border-bottom: 10px solid black;
    }
}


.dashboard-main-block1 {
    width: 550px;
    height: auto;
    border-left: 2px solid #D20097;
}

.dashboard-main-block2 {
    width: 550px;
    height: auto;
    border-left: 2px solid #708300;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.dashboard-main-block3 {
    width: 550px;
    height: 311px;
    border-left: 2px solid #EB9B00;
}

.dashboard-main-block4 {
    width: 550px;
    height: 311px;
    border-left: 2px solid #2B2295;
}

.dashboard-main-block5 {
    width: 356px;
    height: 475px;
    border-left: 2px solid #BAD416;
}

.dashboard-main-block6 {
    width: 740px;
    height: 475px;
    border-left: 2px solid #FA2632;
}

.dashboard-main-block7 {
    width: 550px;
    height: 400px;
    border-left: 2px solid #5048E5;
}

.dashboard-main-block8 {
    width: 550px;
    height: 400px;
    border-left: 2px solid #E80CEC;
}

.dashboard-wallet-block1 {
    width: 550px;
    height: 400px;
    border-left: 2px solid #F80817;
}

.dashboard-wallet-block2 {
    width: 550px;
    height: 400px;
    border-left: 2px solid #000000;
}

.dashboard-main-block1-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}


.dashboard-main-block1-table-row > .dashboard-main-block1-table-cell:nth-child(1) {
    text-align: left;
    width: 50%;
}

.dashboard-main-block1-table-row > .dashboard-main-block1-table-cell:nth-child(2) {
    text-align: right;
    width: 25%;
}

.dashboard-main-block1-table-row > .dashboard-main-block1-table-cell:nth-child(3) {
    text-align: right;
    width: 25%;
}

.green {
    color: #045B02;
}

.dashboard-main-block1-divider {
    margin: 15px 0;
}

.dashboard-main-block2-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dashboard-main-block2-table-cell:first-child {
    width: 300px;
}

.copy-button {
    background: url("../../images/copy.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .copy-button {
        background: url("../../images/copy-white.png") no-repeat;
        background-size: contain;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}

.mobile-block {
    margin-top: 20px;
    padding: 5px 8px;
    border-bottom: 2px solid rgba(62, 62, 62, 0.8);
    border-top: 2px solid rgba(62, 62, 62, 0.8);
}


.dashboard-mobile-table {
    background: #000000;
    border-bottom: 2px solid rgba(62, 62, 62, 0.8);
    border-top: 2px solid rgba(62, 62, 62, 0.8);
    box-sizing: border-box;
    margin-top: 20px;
}

.dashboard-mobile-table > div {
    margin-top: 3px;
}

.dashboard-mobile-table > div:nth-child(1) {
    margin-top: 0;
}

.dashboard-mobile-table-row {
    height: 30px;
    padding: 8px;
    box-sizing: border-box;
    background: #191919;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-mobile-table-cell {

}

.dashboard-mobile-table-cell {
    width: 25%;
    text-align: right;
}

.dashboard-mobile-table-cell:nth-child(1) {
    width: 50%;
    text-align: left;
}

.table {
    margin-top: 15px;
}

.table > thead > tr > th:nth-child(1), .table > tbody > tr > td:nth-child(1) {
    padding-left: 0;
}

@media screen and (max-width: 1000px) {
    .table > tbody > tr > td, .table > thead > tr > th {
        color: #FFFFFF;
        background: #191919 !important;
        border-bottom: 3px solid #000000;
        font-size: 14px;
    }

    .table > thead > tr > th:nth-child(1), .table > tbody > tr > td:nth-child(1) {
        padding-left: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .table-pagination {
        background: #191919 !important;
        color: #FFFFFF !important;
    }

    .MuiTablePagination-actions > button {
        color: #FFFFFF !important;
    }
}

.mobile-margin {
    margin-top: 20px;
    overflow: scroll;
}

.dashboard-block-title {
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
    .dashboard-block-title {
        margin-left: 8px;
    }
}

.dashboard-table-title {
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
    .dashboard-table-title {
        margin-left: 8px;
        margin-bottom: 0;
        font-size: 16px;
    }
    .dashboard-table-marin-mobile {
        margin-top: 50px;
    }
}



/*E-pin*/

.dashboard-epin-block1 {
    width: 550px;
    height: 383px;
    border-left: 2px solid #0C0372;
}

.dashboard-epin-block2 {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    box-sizing: border-box;
    padding: 20px;
    width: 550px;
    height: 158px;
    border-left: 2px solid #0C0372;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {
    .dashboard-epin-block2 {
        display: block;
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
    }
}

.dashboard-epin-block3 {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    box-sizing: border-box;
    padding: 20px;
    width: 550px;
    height: 206px;
    border-left: 2px solid #708300;
}

@media screen and (max-width: 1000px) {
    .dashboard-epin-block3 {
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
        border-left: none;
    }
}

.dashboard-epin-block4 {
    min-width: 100%;
    width: 100%;
    height: auto;
    border-left: 2px solid #0C0372;
    overflow: scroll;
}

@media screen and (max-width: 1000px) {
    .dashboard-epin-block4 {
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
    }
}


.dashboard-statements-block3 {
    min-width: 100%;
    width: 100%;
    height: auto;
    border-left: 2px solid #1302DB;
    overflow: scroll;
}

@media screen and (max-width: 1000px) {
    .dashboard-statements-block3 {
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
    }
}


.without-padding {
    padding: 0 !important;
}

.transferable-checkbox-label > div:nth-child(1) {
    font-size: 14px;
}

.transferable-checkbox-label > div:nth-child(2) {
    font-size: 12px;
    margin-top: 2px;
}

.dashboard-epin-block2-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.dashboard-epin-block2-table-row > .dashboard-epin-block2-table-cell:nth-child(1) {
    text-align: left;
    width: 50%;
}

.dashboard-epin-block2-table-row > .dashboard-epin-block2-table-cell:nth-child(2) {
    text-align: left;
    width: 50%;
}

.email-icon {
    background: url("../../images/table/email.png") no-repeat center;
    background-size: contain;
    width: 26px;
    height: 20px;
    cursor: pointer;
}

.delete-icon {
    background: url("../../images/table/delete.png") no-repeat center;
    background-size: contain;
    width: 26px;
    height: 20px;
    cursor: pointer;
}

/***** End E-pin *****/


.world-map {
    width: 100%;
    height: 475px;
    margin-top: -20px;
}


/***** Account *****/

.dashboard-account-block1 {
    width: 550px;
    height: 500px;
    border-left: 2px solid #0C0372;
}

.dashboard-account-block2 {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    box-sizing: border-box;
    padding: 20px;
    width: 550px;
    height: 166px;
    border-left: 2px solid #6D0000;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {
    .dashboard-account-block2 {
        display: block;
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
    }
}

.dashboard-account-block3 {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    box-sizing: border-box;
    padding: 20px;
    width: 550px;
    height: 317px;
    border-left: 2px solid #747474;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {
    .dashboard-account-block3 {
        display: block;
        padding: 8px;
        background: #191919;
        border-bottom: 10px solid black;
        max-width: 100%;
    }
}

.dashboard-renew-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-renew-info1 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 52%;
}

.dashboard-renew-info2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 47%;
    color: #219653;
}

/***** Statements *****/

.dashboard-statements-block1 {
    width: 550px;
    height: 520px;
    border-left: 2px solid #0C0372;
    overflow-y: scroll;
}

.dashboard-statements-block1-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}


.payout-form-button {
    margin-top: 102px !important;
}

@media screen and (max-width: 1000px) {
    .payout-form-button {
        margin-top: 20px !important;
    }
}

.vault-status {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    padding: 0 20px;
    width: 165px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vault-status > div:nth-child(2) {
    text-align: center;
    width: 70%;
}

.vault-status-disabled {
    background: #D0C9D6;
}

.vault-status-activated {
    background: #FF647C;
}

.vault-status-activate {
    background: #219653;
}

.vault-status-partly-activated {
    background: #FFCF5C;
}

.vault-status-icon {
    background: url("../../images/dashboard/vault-status-icon.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
    margin-right: 20px;
}

.dashboard-account-renew-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 2px solid rgba(113, 113, 113, 0.25);
}

.buy-button-container {
    display: flex;
    column-gap: 5px;
    /*max-width: 205px;*/
    overflow: hidden;
    width: 100%;
}

.upgrade-button-small {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    background: #FFA26B;
    padding: 0 20px;
    width: 80px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy-button-small {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    background: #219653;
    padding: 0 20px;
    width: 80px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.publish-button-small {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    background: #2F80ED;
    padding: 0 20px;
    width: 80px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    background: #219653;
    padding: 0 20px;
    width: 165px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buy-button > div:nth-child(2) {
    text-align: center;
    width: 70%;
}

.buy-button-icon {
    background: url("../../images/dashboard/buy-icon.png") no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
    margin-right: 20px;
}


.education-time-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    padding: 0 20px;
    width: 165px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0084F4;
}

.payonow-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    padding: 0 20px;
    width: 165px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2C94C;
}
