.news-container {
    width: 442px;
    height: 500px;
    overflow-y: scroll;
    padding-bottom: 10px;
}

.news-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.news-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.news-item {
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 10px;
    padding-bottom: 14px;
}

.news-date {
    margin-top: 4px;
    font-size: 12px;
}

.news-title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 600;
}

.news-content {
    margin-top: 14px;
    font-size: 14px;
    white-space: pre-wrap;
}

.news-caption {
    margin-bottom: 20px;
}